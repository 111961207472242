/*------------------------------------------------------------
[8. DASHBOARD]
------------------------------------------------------------*/

.stat-widget {
  p {
    float: left;
    opacity: .6;
  }

  h2 {
    font-size: 28px;
    float: right;
  }

  .progress {
    clear: both;
  }
}

.table-widget {
  img {
    width: 35px;
  }

  .card-body {
    padding: 30px 0 0 0;
  }

  .card-title {
    padding: 0 30px 0 30px;
  }

  .table {
    th, td {
      padding: 15px 30px !important;
    }

    margin-bottom: 0;
  }
}

.card-meet-header {
  margin-bottom: 25px;

  .card-meet-day {
    float: left;
    padding-right: 18px;
    margin-right: 18px;
    text-align: center;
    border-right: 1px solid #d8d6de;
  }
}

.card-meet-text h6 {
  font-size: 16px;
}

.transactions-list {
  margin-bottom: 25px;
}

.tr-item {
  display: flex;
  justify-content: space-between;

  .tr-text {
    h4 {
      font-size: 14px;
      margin-bottom: 2px;
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}

.tr-company-name {
  display: flex;
}

.tr-rate {
  align-self: center;

  p {
    margin-bottom: 0;
    font-weight: 500;
  }
}

.tr-icon {
  margin-right: 16px;
}

.tr-card-icon {
  border-radius: 12px;
  width: 48px;
  height: 48px;
  padding: 11px 14px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.tr-text {
  align-self: center;

  a {
    color: #5b5b5b;
  }
}

/* Widget icons */

.tr-card-bg-primary {
  background-color: #f0f2ff !important;
  color: #7888FC;
}

.tr-card-bg-secondary {
  background-color: #F3F6F9;
  color: #7e8299;
}

.tr-card-bg-success {
  background-color: #d3fbf9;
}

.tr-card-bg-danger {
  background-color: #fad7dd !important;
  color: #EE6E83;
}

.tr-card-bg-warning {
  background-color: #fff5e0 !important;
  color: #ffaf0f;
}

.tr-card-bg-info {
  background-color: #efe8ff !important;
  color: #9C6EFC;
}

/* Loader */

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  .spinner-grow {
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    position: absolute;
    width: 40px;
    height: 40px;
  }
}

.no-loader .loader {
  visibility: hidden;
  opacity: 0;
}
