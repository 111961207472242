label {
    margin-bottom: 0.5rem !important;

    &.required::after {
      color: #dc3545;
      content: " *";
    }
}

dt, dl {
    margin-block: 1rem;
}

dl {
    border-bottom: 1px solid #F3F4F6;
}
