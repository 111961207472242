/*------------------------------------------------------------
[9. RESPONSIVE LAYOUT]
------------------------------------------------------------*/

@media (max-width: 1350px) {
  .page-header .navbar {
    #navbarNav {
      flex: 0;
      margin-right: 15px;
    }

    .logo {
      flex: 0 !important;
    }

    #navbarNav {
      .navbar-nav li a:not(#sidebar-toggle) {
        display: none;
      }

      #leftNav > li > a#sidebar-toggle {
        transform: rotate(-180deg);
      }
    }
  }

  body.sidebar-hidden .page-header .navbar #navbarNav #leftNav > li > a#sidebar-toggle {
    transform: rotate(0deg);
  }

  .page-sidebar {
    transform: scale(0, 1);
    z-index: 999;
  }

  body.sidebar-hidden .page-sidebar {
    transform: scale(1, 1);
  }

  .page-content {
    margin-left: 0;

    .main-wrapper {
      padding-left: 0;
    }
  }

  body.sidebar-hidden .page-content {
    transform: translateX(260px);
    padding-left: 20px;
  }
}

@media (max-width: 1200px) {
  .profile-header {
    margin-top: -60px;
  }

  .profile-cover {
    height: 200px;
  }

  .profile-img {
    display: block;
    float: none !important;

    img {
      margin: 0 auto;
      display: inherit;
      width: 100px !important;
      height: 100px !important;
      border-radius: 100px !important;
    }
  }

  .profile-name {
    display: block;
    float: none !important;
    text-align: center;
    margin: 0 !important;

    h3 {
      color: #333 !important;
    }
  }

  .profile-header .profile-header-menu {
    display: block;
    float: none;
    margin: 0;
    text-align: center;

    ul li {
      display: block;
      padding: 0;
      width: calc(100%/6);
      text-align: center;

      a {
        padding: 10px;
        text-align: center;
        height: auto !important;

        &:hover, &.active {
          color: #000;
        }
      }
    }
  }

  .mail-actions {
    display: none;
  }

  .mail-title {
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .error-image {
    display: none;
  }

  .error-info {
    width: 100%;
    text-align: center;
  }
}
