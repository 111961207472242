/*------------------------------------------------------------
[2. CSS Utilities]
------------------------------------------------------------*/

/* Headings */

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
}

/* Divider */

.divider {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  margin: 30px 0;
}

/* Margins & Paddings */

.no-s {
  margin: 0px !important;
  padding: 0px !important;
}

.no-m {
  margin: 0;
}

.no-p {
  padding: 0;
}

.m {
  margin: 15px;
}

.m-t-xxs {
  margin-top: 5px;
}

.m-r-xxs {
  margin-right: 5px;
}

.m-b-xxs {
  margin-bottom: 5px;
}

.m-l-xxs {
  margin-left: 5px;
}

.m-t-xs {
  margin-top: 10px;
}

.m-r-xs {
  margin-right: 10px;
}

.m-b-xs {
  margin-bottom: 10px;
}

.m-l-xs {
  margin-left: 10px;
}

.m-t-sm {
  margin-top: 15px;
}

.m-r-sm {
  margin-right: 15px;
}

.m-b-sm {
  margin-bottom: 15px;
}

.m-l-sm {
  margin-left: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-r-md {
  margin-right: 20px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-l-md {
  margin-left: 20px;
}

.m-t-lg {
  margin-top: 25px;
}

.m-r-lg {
  margin-right: 25px;
}

.m-b-lg {
  margin-bottom: 25px;
}

.m-l-lg {
  margin-left: 25px;
}

.m-t-xxl {
  margin-top: 30px;
}

.m-r-xxl {
  margin-right: 30px;
}

.m-b-xxl {
  margin-bottom: 30px;
}

.m-l-xxl {
  margin-left: 30px;
}

.m-t-n-xxs {
  margin-top: -5px;
}

.m-r-n-xxs {
  margin-right: -5px;
}

.m-b-n-xxs {
  margin-bottom: -5px;
}

.m-l-n-xxs {
  margin-left: -5px;
}

.m-t-n-xs {
  margin-top: -10px;
}

.m-r-n-xs {
  margin-right: -10px;
}

.m-b-n-xs {
  margin-bottom: -10px;
}

.m-l-n-xs {
  margin-left: -10px;
}

.m-t-n-sm {
  margin-top: -15px;
}

.m-r-n-sm {
  margin-right: -15px;
}

.m-b-n-sm {
  margin-bottom: -15px;
}

.m-l-n-sm {
  margin-left: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-t-n-lg {
  margin-top: -25px;
}

.m-r-n-lg {
  margin-right: -25px;
}

.m-b-n-lg {
  margin-bottom: -25px;
}

.m-l-n-lg {
  margin-left: -25px;
}

.m-t-n-xxl {
  margin-top: -30px;
}

.m-r-n-xxl {
  margin-right: -30px;
}

.m-b-n-xxl {
  margin-bottom: -30px;
}

.m-l-n-xxl {
  margin-left: -30px;
}

.p {
  padding: 15px;
}

.p-h-xxs {
  padding-left: 5px;
  padding-right: 5px;
}

.p-h-xs {
  padding-left: 10px;
  padding-right: 10px;
}

.p-h-sm {
  padding-left: 15px;
  padding-right: 15px;
}

.no-p-h {
  padding-left: 0;
  padding-right: 0;
}

.no-p-v {
  padding-top: 0;
  padding-bottom: 0;
}

.p-h-md {
  padding-left: 20px;
  padding-right: 20px;
}

.p-h-lg {
  padding-left: 25px;
  padding-right: 25px;
}

.p-h-xxl {
  padding-left: 30px;
  padding-right: 30px;
}

.p-v-xxs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-v-xs {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-v-sm {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-v-md {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-v-lg {
  padding-top: 25px;
  padding-bottom: 25px;
}

.p-v-xxl {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Width & Height */

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

.w-xxs {
  width: 50px;
}

.w-xs {
  width: 90px;
}

.w-sm {
  width: 140px;
}

.w {
  width: 200px;
}

.w-md {
  width: 240px;
}

.w-lg {
  width: 280px;
}

.w-xl {
  width: 330px;
}

.w-xxl {
  width: 370px;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

/* Borders */

.no-b {
  border: 0 !important;
}

.b {
  border: 1px solid #eaecf3;
}

.b-t {
  border-top: 1px solid #eaecf3;
}

.b-r {
  border-right: 1px solid #eaecf3;
}

.b-b {
  border-bottom: 1px solid #eaecf3;
}

.b-l {
  border-left: 1px solid #eaecf3;
}

.b-default {
  border-color: #eaecf3;
}

.b-primary {
  border-color: #7888FC;
}

.b-info {
  border-color: #9C6EFC;
}

.b-success {
  border-color: #6bcac2;
}

.b-warning {
  border-color: #ffaf0f;
}

.b-danger {
  border-color: #EE6E83;
}

.b-2 {
  border-width: 2px;
}

.b-3 {
  border-width: 3px;
}

.b-4 {
  border-width: 4px;
}

.b-n-t {
  border-top: 0;
}

.b-n-r {
  border-right: 0;
}

.b-n-b {
  border-bottom: 0;
}

.b-n-l {
  border-left: 0;
}

/* Radius */

.no-r {
  border-radius: 0 !important;
}

.r {
  border-radius: 15px;
}

.r-t-l {
  border-top-left-radius: 15px;
}

.r-t-r {
  border-top-right-radius: 15px;
}

.r-b-l {
  border-bottom-left-radius: 15px;
}

.r-b-r {
  border-bottom-right-radius: 15px;
}

.no-r-t-l {
  border-top-left-radius: 0 !important;
}

.no-r-t-r {
  border-top-right-radius: 0 !important;
}

.no-r-b-l {
  border-bottom-left-radius: 0 !important;
}

.no-r-b-r {
  border-bottom-right-radius: 0 !important;
}

/* Backgrounds */

.bg-primary {
  background-color: #7888FC !important;
}

.bg-secondary {
  background-color: #E4E6EF !important;
}

.bg-success {
  background-color: #6bcac2 !important;
}

.bg-danger {
  background-color: #f75d6fd8 !important;
}

.bg-warning {
  background-color: #f5af47ea !important;
}

.bg-info {
  background-color: #9465fcea !important;
}

.bg-light {
  background-color: #F3F6F9 !important;
}

.bg-dark {
  background-color: #242e44 !important;
}

/* Text Colors */

.text-white {
  color: #fff;
}

.text-primary {
  color: #7888FC !important;
}

.text-secondary {
  color: #a5a5a5 !important;
}

.text-success {
  color: #6bcac2 !important;
}

.text-danger {
  color: #f75d6fd8 !important;
}

.text-warning {
  color: #f5af47ea !important;
}

.text-info {
  color: #9465fcea !important;
}

.text-light {
  color: #F3F6F9 !important;
}

.text-dark {
  color: #5b5b5b !important;
}
