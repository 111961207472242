/*------------------------------------------------------------
[1. Global Styles]
------------------------------------------------------------*/

html, body {
  font-size: 14px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

html {
  overflow-x: hidden;
}

body {
  background-color: #f3f4f7;
  font-weight: normal;
  color: #5b5b5b;
  line-height: 1.6;
}

a {
  color: #7888FC;
  text-decoration: none;

  &:hover {
    color: #7888FC;
  }
}

p {
  line-height: 1.6;
}

small {
  font-size: 80%;
  font-weight: 400;
}

menu {
  padding-left: 0px;
}

body, span, text {
  font-family: 'Poppins', sans-serif;
}

a {
  font-family: 'Poppins', sans-serif;

  &:hover, &:active, &:focus {
    outline: none !important;
    text-decoration: none !important;
  }
}

b, strong, .bold {
  font-weight: 600;
}

dt {
  font-weight: 500;
}
