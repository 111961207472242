/*------------------------------------------------------------
[6. PLUGINS]
------------------------------------------------------------*/

/* BlockUI */

.blockUI {
  &.blockOverlay {
    border-radius: inherit;
    background: rgba(255, 255, 255) !important;
    opacity: 0.8 !important;
  }

  &.blockMsg {
    background: transparent !important;
    width: 25px !important;
    border: 0 !important;
    height: 25px !important;
    left: calc(50% - 12.5px) !important;
    top: calc(50% - 12.5px) !important;
  }
}
