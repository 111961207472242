/*------------------------------------------------------------
[5. UI ELEMENTS]
------------------------------------------------------------*/

/* Popovers */

.popover {
  margin: 20px;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
  color: #5b5b5b;
  font-family: "Poppins", sans-serif !important;
}

.popover-header {
  padding: 15px 25px 0;
  background: transparent;
  border-bottom-color: transparent;
  font-size: 14px;
  opacity: .7;
}

.popover-body {
  padding: 15px 25px;
  font-size: 13px;
  color: #7D7D83;
}

.popover .arrow:before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::before {
  display: none;
}

/* Row */

.row > * {
  padding-right: 14px;
  padding-left: 14px;
}

/* Cards */

.card {
  margin-bottom: 28px;
  border-radius: 15px;
  border: none;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
}

.card-title {
  font-size: 18px;
  color: #5b5b5b;
  margin-bottom: 30px;
}

.card-body {
  padding: 30px;
}

.card-description {
  margin-bottom: 20px;
}

.card-img, .card-img-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.card-header, .card-footer {
  border-color: #E4E6EF;
}

.list-group-item {
  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  padding: 15px;
  border-color: #E4E6EF;

  &.active {
    background-color: #7888FC;
    border-color: #7888FC;
  }
}

.list-group-item-primary {
  color: #7888FC;
  background-color: #f0f2ff;
  font-weight: 500;
}

.list-group-item-secondary {
  color: #7e8299;
  background-color: #F3F6F9;
  font-weight: 500;
}

.list-group-item-success {
  color: #6bcac2;
  background-color: #d3fbf9;
  font-weight: 500;
}

.list-group-item-danger {
  color: #EE6E83;
  background-color: #fad7dd;
  font-weight: 500;
}

.list-group-item-warning {
  color: #ffaf0f;
  background-color: #fff5e0;
  font-weight: 500;
}

.list-group-item-info {
  color: #9C6EFC;
  background-color: #efe8ff;
  font-weight: 500;
}

.list-group-item-light {
  color: #7e8299;
  background-color: #F3F6F9;
  font-weight: 500;
}

.list-group-item-dark {
  color: #5b5b5b;
  background-color: #D1D3E0;
  font-weight: 500;
}

.card-header {
  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  padding: 15px;
  background-color: #fff;
}

.card-footer {
  padding: 15px;
  background-color: #fff;

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.card-img-overlay {
  border-radius: 15px;
}

.card {
  &.bg-primary {
    .card-header {
      background-color: #7888FC;
      border-color: #7888FC;
    }

    .card-title {
      color: #fff;
    }
  }

  &.bg-secondary {
    .card-header {
      background-color: #E4E6EF;
      color: #5b5b5b;
    }

    .card-body {
      color: #5b5b5b;
    }
  }

  &.bg-success {
    .card-header {
      background-color: #6bcac2;
      border-color: #6bcac2;
    }

    .card-title {
      color: #fff;
    }
  }

  &.bg-danger {
    .card-header {
      background-color: #f75d6fd8;
      border-color: #f75d6fd8;
    }

    .card-title {
      color: #fff;
    }
  }

  &.bg-warning {
    .card-header {
      background-color: #f5af47ea;
      border-color: #f5af47ea;
    }

    .card-title {
      color: #fff;
    }
  }

  &.bg-info {
    .card-header {
      background-color: #9465fcea;
      border-color: #9465fcea;
    }

    .card-title {
      color: #fff;
    }
  }

  &.bg-dark {
    .card-header {
      background-color: #242e44;
      border-color: #242e44;
    }

    .card-title {
      color: #fff;
    }
  }
}

/* Navs */

.nav-pills {
  .nav-link.active, .show > .nav-link {
    background-color: #7888FC;
  }

  .nav-link {
    border-radius: 10px;
  }
}

.nav-tabs {
  .nav-item.show .nav-link {
    border: 0;
  }

  .nav-link {
    &.active {
      border: 0;
    }

    border: 0;
  }
}

/* Toasts */

.toast {
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 1.25rem rgb(31 45 61 / 8%);
}

.toast-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px 15px;
}

.toast-body {
  padding: 15px;
}

.toast-header strong {
  font-weight: 500;
}

/* Alerts */

.alert {
  border-radius: 15px;
  padding: 12px 15px;
  font-weight: 500;
}

.alert-primary {
  background-color: #f0f2ff;
  border-color: #f0f2ff;
  color: #7888FC;
}

.alert-secondary {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #7e8299;
}

.alert-success {
  background-color: #d3fbf9;
  border-color: #d3fbf9;
  color: #6bcac2;
}

.alert-danger {
  background-color: #fad7dd;
  border-color: #fad7dd;
  color: #EE6E83;
}

.alert-warning {
  background-color: #fff5e0;
  border-color: #fff5e0;
  color: #ffaf0f;
}

.alert-info {
  background-color: #efe8ff;
  border-color: #efe8ff;
  color: #9C6EFC;
}

.alert-light {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.alert-dark {
  background-color: #D1D3E0;
  border-color: #D1D3E0;
  color: #5b5b5b;
}

.alert-primary.outline-alert {
  background-color: #fff;
  border-color: #7888FC;
  color: #7888FC;
  font-weight: normal;
}

.alert-secondary.outline-alert {
  background-color: #fff;
  border-color: #E4E6EF;
  font-weight: normal;
}

.alert-success.outline-alert {
  background-color: #fff;
  border-color: #6bcac2;
  color: #6bcac2;
  font-weight: normal;
}

.alert-danger.outline-alert {
  background-color: #fff;
  border-color: #f75d6fd8;
  color: #f75d6fd8;
  font-weight: normal;
}

.alert-warning.outline-alert {
  background-color: #fff;
  border-color: #f5af47ea;
  color: #f5af47ea;
  font-weight: normal;
}

.alert-info.outline-alert {
  background-color: #fff;
  border-color: #9465fcea;
  color: #9465fcea;
  font-weight: normal;
}

.alert-light.outline-alert {
  background-color: #fff;
  border-color: #F3F6F9;
  font-weight: normal;
}

.alert-dark.outline-alert {
  background-color: #fff;
  border-color: #242e44;
  color: #383d41;
  font-weight: normal;
}

/* Icons */

.icon-list-row {
  margin-top: 30px;
  text-align: center;

  i {
    font-size: 23px;
  }
}

.icon-state-info {
  color: #7888FC;
}

/* Badge */

.badge {
  font-weight: 500;
  padding: 7px 12px;

  &:not(.badge-pill) {
    border-radius: 7px;
  }

  &.bg-primary {
    background-color: #f0f2ff !important;
    color: #7888FC;
  }

  &.bg-secondary {
    background-color: #F3F6F9 !important;
    color: #7e8299 !important;
  }

  &.bg-success {
    background-color: #d3fbf9 !important;
    color: #6bcac2;
  }

  &.bg-danger {
    background-color: #fad7dd !important;
    color: #EE6E83;
  }

  &.bg-warning {
    background-color: #fff5e0 !important;
    color: #ffaf0f;
  }

  &.bg-info {
    background-color: #efe8ff !important;
    color: #9C6EFC;
  }

  &.bg-dark {
    background-color: #D1D3E0 !important;
    color: #5b5b5b;
  }
}

/* Buttons */

.btn {
  padding: 8px 20px;
  border-radius: 10px;
  font-weight: 500;
}

.btn-primary {
  background-color: #f0f2ff;
  border-color: #f0f2ff;
  color: #7888FC;

  &.disabled, &:disabled {
    background-color: #f0f2ff;
    border-color: #f0f2ff;
    color: #7888FC;
  }

  &:hover, &.focus, &:focus {
    background-color: #f0f2ff;
    border-color: #f0f2ff;
    color: #7888FC;
    box-shadow: 0 7px 23px -8px #f0f2ff;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #f0f2ff;
      border-color: #f0f2ff;
      color: #7888FC;
      box-shadow: 0 7px 23px -8px #f0f2ff;
    }
  }
}

.btn-secondary {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #7e8299;

  &.disabled, &:disabled {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #7e8299;
  }

  &:hover, &.focus, &:focus {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #7e8299;
    box-shadow: 0 7px 23px -8px #F3F6F9;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #F3F6F9;
      border-color: #F3F6F9;
      color: #7e8299;
      box-shadow: 0 7px 23px -8px #F3F6F9;
    }
  }
}

.btn-success, .btn-sucess.disabled {
  background-color: #d3fbf9;
  border-color: #d3fbf9;
  color: #6bcac2;
}

.btn-success {
  &:disabled {
    background-color: #d3fbf9;
    border-color: #d3fbf9;
    color: #6bcac2;
  }

  &:hover, &.focus, &:focus {
    background-color: #d3fbf9;
    border-color: #d3fbf9;
    color: #6bcac2;
    box-shadow: 0 7px 23px -8px #d3fbf9;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #d3fbf9;
      border-color: #d3fbf9;
      color: #6bcac2;
      box-shadow: 0 7px 23px -8px #d3fbf9;
    }
  }
}

.btn-danger {
  background-color: #fad7dd;
  border-color: #fad7dd;
  color: #EE6E83;

  &.disabled, &:disabled {
    background-color: #fad7dd;
    border-color: #fad7dd;
    color: #EE6E83;
  }

  &:hover, &.focus, &:focus {
    background-color: #fad7dd;
    border-color: #fad7dd;
    color: #EE6E83;
    box-shadow: 0 7px 23px -8px #fad7dd;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #fad7dd;
      border-color: #fad7dd;
      color: #EE6E83;
      box-shadow: 0 7px 23px -8px #fad7dd;
    }
  }
}

.btn-warning {
  background-color: #fff5e0;
  border-color: #fff5e0;
  color: #ffaf0f;

  &.disabled, &:disabled {
    background-color: #fff5e0;
    border-color: #fff5e0;
    color: #ffaf0f;
  }

  &:hover, &.focus, &:focus {
    background-color: #fff5e0;
    border-color: #fff5e0;
    color: #ffaf0f;
    box-shadow: 0 7px 23px -8px #fff5e0;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #fff5e0;
      border-color: #fff5e0;
      color: #ffaf0f;
      box-shadow: 0 7px 23px -8px #fff5e0;
    }
  }
}

.btn-info {
  background-color: #efe8ff;
  border-color: #efe8ff;
  color: #9C6EFC;

  &.disabled, &:disabled {
    background-color: #efe8ff;
    border-color: #efe8ff;
    color: #9C6EFC;
  }

  &:hover, &.focus, &:focus {
    background-color: #efe8ff;
    border-color: #efe8ff;
    color: #9C6EFC;
    box-shadow: 0 7px 23px -8px #efe8ff;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #efe8ff;
      border-color: #efe8ff;
      color: #9C6EFC;
      box-shadow: 0 7px 23px -8px #efe8ff;
    }
  }
}

.btn-light {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #7e8299;

  &.disabled, &:disabled {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #7e8299;
  }

  &:hover .btn-light.focus, &:focus {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #7e8299;
    box-shadow: 0 7px 23px -8px #F3F6F9;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #F3F6F9;
      border-color: #F3F6F9;
      color: #7e8299;
      box-shadow: 0 7px 23px -8px #F3F6F9;
    }
  }
}

.btn-dark {
  background-color: #D1D3E0;
  border-color: #D1D3E0;
  color: #5b5b5b;

  &.disabled, &:disabled {
    background-color: #D1D3E0;
    border-color: #D1D3E0;
    color: #5b5b5b;
  }

  &:hover, &.focus, &:focus {
    background-color: #D1D3E0;
    border-color: #D1D3E0;
    color: #5b5b5b;
    box-shadow: 0 7px 23px -8px #D1D3E0;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      background-color: #D1D3E0;
      border-color: #D1D3E0;
      color: #5b5b5b;
      box-shadow: 0 7px 23px -8px #D1D3E0;
    }
  }
}

.btn-link {
  color: #7888FC;
  text-decoration: none;

  &:hover {
    color: #7888FC;
    text-decoration: underline;
  }
}

.btn-outline-primary {
  color: #7888FC;
  border-color: #7888FC;

  &:hover, &.focus, &:focus {
    color: #fff;
    border-color: #7888FC;
    background-color: #7888FC;
    box-shadow: 0 7px 23px -8px #7888FC;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff;
      border-color: #7888FC;
      background-color: #7888FC;
      box-shadow: 0 7px 23px -8px #7888FC;
    }
  }
}

.btn-outline-secondary {
  color: #5b5b5b;
  border-color: #E4E6EF;

  &:hover, &.focus, &:focus {
    color: #5b5b5b;
    border-color: #E4E6EF;
    background-color: #E4E6EF;
    box-shadow: 0 7px 23px -8px #E4E6EF;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #5b5b5b;
      border-color: #E4E6EF;
      background-color: #E4E6EF;
      box-shadow: 0 7px 23px -8px #E4E6EF;
    }
  }
}

.btn-outline-success {
  color: #6bcac2;
  border-color: #6bcac2;

  &:hover, &.focus, &:focus {
    color: #fff;
    border-color: #6bcac2;
    background-color: #6bcac2;
    box-shadow: 0 7px 23px -8px #6bcac2;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff;
      border-color: #6bcac2;
      background-color: #6bcac2;
      box-shadow: 0 7px 23px -8px #6bcac2;
    }
  }
}

.btn-outline-danger {
  color: #f75d6fd8;
  border-color: #f75d6fd8;

  &:hover, &.focus, &:focus {
    color: #fff;
    border-color: #f75d6fd8;
    background-color: #f75d6fd8;
    box-shadow: 0 7px 23px -8px #f75d6fd8;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff;
      border-color: #f75d6fd8;
      background-color: #f75d6fd8;
      box-shadow: 0 7px 23px -8px #f75d6fd8;
    }
  }
}

.btn-outline-warning {
  color: #f5af47ea;
  border-color: #f5af47ea;

  &:hover, &.focus, &:focus {
    color: #fff;
    border-color: #f5af47ea;
    background-color: #f5af47ea;
    box-shadow: 0 7px 23px -8px #f5af47ea;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff;
      border-color: #f5af47ea;
      background-color: #f5af47ea;
      box-shadow: 0 7px 23px -8px #f5af47ea;
    }
  }
}

.btn-outline-info {
  color: #9465fcea;
  border-color: #9465fcea;

  &:hover, &.focus, &:focus {
    color: #fff;
    border-color: #9465fcea;
    background-color: #9465fcea;
    box-shadow: 0 7px 23px -8px #9465fcea;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff;
      border-color: #9465fcea;
      background-color: #9465fcea;
      box-shadow: 0 7px 23px -8px #9465fcea;
    }
  }
}

.btn-outline-dark {
  color: #5b5b5b;
  border-color: #D1D3E0;

  &:hover, &.focus, &:focus {
    color: #5b5b5b;
    border-color: #D1D3E0;
    background-color: #D1D3E0;
    box-shadow: 0 7px 23px -8px #D1D3E0;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #5b5b5b;
      border-color: #D1D3E0;
      background-color: #D1D3E0;
      box-shadow: 0 7px 23px -8px #D1D3E0;
    }
  }
}

/* Modals */

body.modal-open {
  overflow: visible;
}

.modal-content {
  border-radius: 15px;
  border: none;
}

.modal-header, .modal-footer {
  padding: 30px;
  border: 0;
}

.modal-body {
  padding: 0 30px;
}

/* Dropdowns */

.dropdown-menu {
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 1.25rem rgb(31 45 61 / 5%);
  padding: 15px 0;
  margin: 10px 0;
}

.dropdown-item {
  color: #5b5b5b;
  padding: 9px 26px;

  &:hover {
    background: none;
    color: #7888FC;
  }
}

.dropdown-divider {
  border-color: #eaecf3;
}

.dropdown-menu-dark {
  background-color: #D1D3E0;

  .dropdown-item {
    color: #5b5b5b;

    &:hover {
      background: none;
      color: #7888FC;
    }

    &.active, &:active {
      color: #7888FC;
      background: none;
    }
  }
}

.dropdown-item {
  &.active, &:active {
    color: #7888FC;
    background: none;
  }
}

.dropdown-header {
  padding: 8px 26px;
}

.dropdown-menu form {
  padding: 8px 20px;
}

/* Pagination */

.page-link {
  color: #7888FC;
  border-color: #E4E6EF;
}

.page-item {
  &.disabled .page-link {
    border-color: #E4E6EF;
  }

  &:first-child .page-link {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child .page-link {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.active .page-link {
    background-color: #7888FC;
    border-color: #7888FC;
  }
}

/* Progress */

.progress {
  background-color: #F5F6FA;
}

.progress-bar {
  background-color: #7888FC;
}

/* Accordion */

.accordion-button {
  border: none;
  -webkit-box-shadow: 0 0 7px 0 rgba(63, 71, 105, 0.08);
  box-shadow: 0 0 7px 0 rgba(63, 71, 105, 0.08);
  padding: 20px;
}

.accordion-collapse {
  border: none;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #7888FC;
}

.accordion-item {
  &:first-of-type .accordion-button {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.accordion-flush .accordion-button {
  box-shadow: none;
}

/* Form Elements */

.form-control {
  border-color: #E4E6EF;
  border-radius: 10px;
  padding: .65rem 1rem;

  &:disabled, &[readonly] {
    background-color: #F5F6FA;
    color: #212529;
  }
}

.form-select:disabled {
  background-color: #F5F6FA;
  color: #212529;
}

.form-control-color {
  padding: 7px;
}

.form-select {
  border-color: #E4E6EF;
  border-radius: 10px;
  padding: .65rem 1rem;
}

.form-check-input:checked {
  background-color: #9465fcea;
  border-color: #9465fcea;
}

.input-group-text {
  border-radius: 10px;
  background-color: #F7F7F7;
  border-color: #E4E6EF;
}

.form-control.is-valid, .was-validated .form-control:valid, .form-select.is-valid, .was-validated .form-select:valid {
  border-color: #6bcac2;
}

.valid-feedback, .form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #6bcac2;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #6bcac2;
  border-color: #6bcac2;
}

.form-control.is-invalid, .was-validated .form-control:invalid, .form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #f75d6fd8;
}

.invalid-feedback, .form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #f75d6fd8;
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #f75d6fd8;
}

/* Tables */

.table {
  color: #5b5b5b;
  border-color: #E4E6EF;

  img {
    margin-right: 10px;
  }
}

.table-primary {
  --bs-table-bg:#f0f2ff;
  --bs-table-striped-bg:#f0f2ff;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#f0f2ff;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#f0f2ff;
  --bs-table-hover-color:#000;
  color: #7888FC;
  border-color: #f0f2ff;
}

.table-secondary {
  --bs-table-bg:#F3F6F9;
  --bs-table-striped-bg:#F3F6F9;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#F3F6F9;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#F3F6F9;
  --bs-table-hover-color:#000;
  color: #7e8299;
  border-color: #F3F6F9;
}

.table-success {
  --bs-table-bg:#d3fbf9;
  --bs-table-striped-bg:#d3fbf9;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#d3fbf9;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#d3fbf9;
  --bs-table-hover-color:#000;
  color: #6bcac2;
  border-color: #d3fbf9;
}

.table-danger {
  --bs-table-bg:#fad7dd;
  --bs-table-striped-bg:#fad7dd;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#fad7dd;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#fad7dd;
  --bs-table-hover-color:#000;
  color: #EE6E83;
  border-color: #fad7dd;
}

.table-warning {
  --bs-table-bg:#fff5e0;
  --bs-table-striped-bg:#fff5e0;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#fff5e0;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#fff5e0;
  --bs-table-hover-color:#000;
  color: #ffaf0f;
  border-color: #fff5e0;
}

.table-info {
  --bs-table-bg:#efe8ff;
  --bs-table-striped-bg:#efe8ff;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#efe8ff;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#efe8ff;
  --bs-table-hover-color:#000;
  color: #9C6EFC;
  border-color: #efe8ff;
}

.table-light {
  --bs-table-bg:#F3F6F9;
  --bs-table-striped-bg:#F3F6F9;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#F3F6F9;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#F3F6F9;
  --bs-table-hover-color:#000;
  color: #5b5b5b;
  border-color: #F3F6F9;
}

.table-dark {
  --bs-table-bg:#D1D3E0;
  --bs-table-striped-bg:#D1D3E0;
  --bs-table-striped-color:#fff;
  --bs-table-active-bg:#D1D3E0;
  --bs-table-active-color:#fff;
  --bs-table-hover-bg:#D1D3E0;
  --bs-table-hover-color:#fff;
  color: #5b5b5b;
  border-color: #D1D3E0;

  th {
    background-color: #D1D3E0;
  }
}

.table-light th {
  background-color: #F3F6F9;
}

.table {
  * {
    border-color: #EAECF3 !important;
  }

  th, td {
    padding: 15px 20px !important;
    font-weight: normal;
    vertical-align: middle;
  }

  tbody tr:last-child {
    td, th {
      border-bottom: none;
    }
  }

  tfoot tr {
    td, th {
      border-bottom: none;
      border-top-width: 1px;
    }
  }
}

.table-hover > tbody > tr {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  &:hover {
    --bs-table-hover-bg:transparent;
    background: #F5F6FA;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-striped-bg:#F5F6FA;
  color: #5b5b5b;
}

/*------------------------------------------------------------
[6. PLUGINS]
------------------------------------------------------------*/

/* BlockUI */

.blockUI {
  &.blockOverlay {
    border-radius: inherit;
    background: rgba(255, 255, 255) !important;
    opacity: 0.8 !important;
  }

  &.blockMsg {
    background: transparent !important;
    width: 25px !important;
    border: 0 !important;
    height: 25px !important;
    left: calc(50% - 12.5px) !important;
    top: calc(50% - 12.5px) !important;
  }
}

/* Datatables */

table.dataTable {
  margin-top: 20px !important;
  margin-bottom: 20px !important;

  td, th {
    padding: 15px 25px !important;
    border-bottom: 1px solid #e8e8e8 !important;
  }

  tfoot th {
    border-bottom: 0 !important;
  }

  thead th, tfoot th {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: rgba(92, 102, 98, 0.8);
  }

  thead {
    .sorting:before, .sorting_asc:before, .sorting_desc:before, .sorting_asc_disabled:before, .sorting_desc_disabled:before {
      content: '\e316';
      font-family: 'Material Icons';
      font-size: 14px;
      right: 10px;
      bottom: calc(50% - 4px);
    }

    .sorting:after, .sorting_asc:after, .sorting_desc:after, .sorting_asc_disabled:after, .sorting_desc_disabled:after {
      content: '\e313';
      font-family: 'Material Icons';
      font-size: 14px;
      right: 10px;
      bottom: calc(50% - 14px);
    }
  }
}

div.dataTables_wrapper div {
  &.dataTables_length select {
    margin: 0 10px;
  }

  &.dataTables_filter input {
    margin-left: 10px;
  }
}

tr.group {
  background-color: #fafafa !important;
}

/*------------------------------------------------------------
[7. PAGES]
------------------------------------------------------------*/

/* Error Pages */

.error-page {
  .container, .error-container {
    height: 100%;
    position: relative;
  }
}

.error-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  h1 {
    font-size: 148px;
    color: #7888FC;
  }

  p {
    font-size: 14px;
  }
}

.error-image {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50%;
  // background: url(assets/images/500.svg) center center no-repeat;
  background-size: contain;
}

body {
  &.err-500 .error-image {
    // background: url(assets/images/500.svg) center center no-repeat;
    background-size: contain;
  }

  &.error-page .container {
    max-width: 1140px;
  }
}

/* Invoice */

.invoice-details {
  margin-top: 20px;
  margin-bottom: 40px;

  p {
    margin-bottom: 3px;

    &.info {
      font-weight: 500;
      text-transform: uppercase;
      color: #3a3a3a;
      margin-bottom: 10px;
    }
  }
}

.invoice-info p {
  margin-bottom: 8px;

  span {
    float: right;
  }
}

.invoice-table {
  th {
    font-weight: 500;
  }

  img {
    width: 35px;
    height: 35px;
  }

  a {
    color: #5b5b5b;
    margin-right: 5px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.invoice-last {
  margin-top: 40px;
}

/* Authentication */

.login-page .container {
  height: 100vh;

  .row {
    height: 100vh;

    .login-box-container {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.authent-logo {
  text-align: center;
}

.authent-text {
  text-align: center;
  margin: 20px 0;
  font-size: 16px;

  p {
    margin-bottom: 5px;
  }
}

.authent-reg {
  text-align: center;
  margin-top: 20px;

  p {
    margin-bottom: 0;
  }
}

.authent-login {
  margin-top: 10px;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

/* Pricing Tables */

.io-pricing-table {
  text-align: center;

  li {
    padding: 20px;
  }
}

/* Mail */

.email-page .main-wrapper {
  padding-bottom: 0;

  > .row > div > .card {
    margin-bottom: 0;
  }
}

.email-list {
  max-height: calc(100vh - 207px);
  overflow-y: auto;

  ul {
    margin-bottom: 0px;
  }
}

.email-list-item {
  color: #5b5b5b;

  .email-author {
    img {
      width: 35px;
      border-radius: 100%;
    }

    span {
      &.author-name {
        margin-left: 7px;
        line-height: 25px;
      }

      &.email-date {
        float: right;
        opacity: .6;
        line-height: 25px;
        font-size: 12px;
      }
    }
  }

  .email-info {
    display: block;
    margin-top: 8px;

    .email-subject {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    .email-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      opacity: .6;
    }
  }
}

.email-list li a {
  &:active, &:focus {
    color: #5b5b5b;
  }
}

.email-menu li a {
  &:active, &:focus {
    color: #5b5b5b;
  }
}

.email-list li {
  a {
    border-radius: 15px;
    display: block;
    padding: 18px 12px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  &:last-child a {
    border-color: transparent;
  }

  a:hover {
    background: #F3F6F9;
  }

  &.active a {
    margin-bottom: 5px;
    background: #F3F6F9;
  }
}

.open-email-content {
  max-height: calc(100vh - 207px);
  overflow-y: auto;
}

.mail-header {
  width: 100%;
  display: block;
  overflow: hidden;
}

.mail-title {
  float: left;
  line-height: 20px;
  padding: 4px 0;
  max-width: calc(100% - 285px);
}

.mail-actions {
  float: right;
}

.mail-info {
  display: block;
  width: 100%;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #EAECF3;

  .mail-author {
    float: left;

    img {
      width: 50px;
      border-radius: 50px;
      border: 4px solid #f9f9f9;
      float: left;
    }
  }

  .mail-author-info {
    padding: 0 10px;
    display: inline-block;
    height: 50px;
  }

  .mail-author span {
    &.mail-author-name {
      display: block;
      line-height: 25px;
    }

    &.mail-author-address {
      display: block;
      line-height: 25px;
      opacity: 0.6;
    }
  }

  .mail-other-info {
    float: right;

    span {
      line-height: 50px;
    }
  }
}

.mail-text, .mail-attachment {
  margin-top: 30px;
}

.mail-attachment-files {
  margin-top: 30px;

  .card {
    width: 200px;
    float: left;
    margin-right: 20px;

    .card-body {
      padding: 15px;
      font-size: 14px;
    }

    .card-title {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

/* File Manager */

.file-manager-menu {
  ul li {
    padding: 10px;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    a {
      &:hover {
        margin-left: 5px;
      }

      color: #5b5b5b;
      -webkit-transition: all .1s ease-in-out;
      -moz-transition: all .1s ease-in-out;
      -o-transition: all .1s ease-in-out;
      transition: all .1s ease-in-out;

      i {
        padding-right: 10px;
      }
    }
  }

  &.label-menu ul li a i {
    width: 6px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    display: inline-block;
    vertical-align: middle;
    margin: 0 16px 0 6px;
  }
}

.fmm-title {
  text-transform: uppercase;
  padding: 10px;
  display: block;
  opacity: 0.6;
}

.file-manager-menu .progress {
  margin-top: 10px;
  margin-left: 10px;
}

.storage-info-text {
  padding: 10px;
}

.card-file-manager .card-text {
  opacity: 0.6;
}

.card-file-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  vertical-align: middle;
  font-size: 48px;
  text-align: center;
  line-height: 130px;
  background: #fbfbfb;
}

.folder {
  .folder-icon {
    float: left;

    i {
      font-size: 43px;
    }
  }

  .folder-info {
    display: block;
    margin-left: 20px;
    float: left;

    a {
      margin-bottom: 0;
      display: block;
      color: #5b5b5b;
    }

    span {
      opacity: 0.6;
    }
  }
}

/* Social */

.profile-cover {
  width: 100%;
  display: block;
  position: relative;
  border-radius: 15px;
  z-index: 1;
  height: 350px;
  // background: url(assets/images/profile-bg.png) center center no-repeat;
  background-size: cover;

  &:after {
    display: block;
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    background: linear-gradient(rgba(0, 0, 0, 0.3), black);
  }
}

.profile-header {
  display: block;
  width: 100%;
  margin-top: -110px;
  padding: 0 30px;
  overflow: hidden;
  z-index: 2;
  position: relative;
  margin-bottom: 20px;

  .profile-img {
    img {
      width: 150px;
      height: 150px;
      border-radius: 150px;
    }

    float: left;
  }

  .profile-name {
    float: left;
    margin-top: 50px;
    margin-left: 30px;

    h3 {
      color: #fff;
      font-weight: 400;
      font-size: 22px;
      line-height: 50px;
    }
  }

  .profile-header-menu {
    float: right;
    height: 100%;
    margin-top: 39px;

    ul li {
      float: left;
      height: 100%;
      padding: 25px;

      a {
        color: #7D7D83;
        display: block;
        font-weight: normal;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;

        &:hover, &.active {
          color: #fff;
        }
      }
    }
  }
}

ul.profile-about-list {
  margin-bottom: 0;

  li {
    margin-bottom: 10px;

    &.profile-about-list-buttons {
      margin-bottom: 0;
    }
  }
}

.story-list .story {
  overflow: hidden;
  display: block;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #7888FC;
    padding: 2px;
    float: left;
  }

  .story-info {
    float: left;
    padding: 5px 15px;

    span {
      &.story-author {
        display: block;
        line-height: 20px;
        color: #5b5b5b;
      }

      &.story-time {
        line-height: 20px;
        font-size: 13px;
        opacity: 0.6;
      }
    }
  }
}

.post-header, .post-body {
  display: block;
  overflow: hidden;
  width: 100%;
}

.post-header {
  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    float: left;
  }

  .post-info {
    float: left;
    padding: 4px 15px 0 15px;

    span.post-date {
      font-size: 13px;
      opacity: 0.6;
    }
  }

  .post-header-actions {
    float: right;
    padding: 15px 0;

    a {
      line-height: 20px;
      color: #646464;

      &:hover {
        color: #333;
      }
    }
  }
}

.post-body {
  padding: 15px 0 0 0;
  position: relative;

  p {
    margin-bottom: 0;
  }
}

.post-header .post-info span.post-author {
  font-size: 15px;
}

.post-body .post-image {
  display: block;
  width: 100%;
  border-radius: 6px;
  margin-top: 15px;
}

.post-actions {
  display: block;
  overflow: hidden;
  margin-bottom: 0;

  ul {
    display: block;
    overflow: hidden;
    margin-bottom: 0;

    li a {
      float: left;
      padding: 5px 8px;
      font-size: 15px;
      color: #5b5b5b;
      margin-right: 10px;

      &:hover {
        color: #000;
      }

      &.liked {
        color: #844FF1;

        i:before {
          content: '\f004';
        }
      }

      i {
        margin-right: 7px;
      }
    }
  }

  margin-top: 15px;
  padding: 5px 10px 0;
}

.post-comments {
  margin-top: 5px;
  padding-top: 15px;

  .post-comm {
    display: block;
    margin-bottom: 10px;
    background: #F3F6FA;
    border-radius: 15px;
    padding: 15px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      float: left;
    }

    .comment-container {
      float: left;
      padding: 0 10px;
      width: calc(100% - 30px);
      display: block;

      span.comment-author {
        color: #5b5b5b;
        display: block;
        line-height: 30px;

        small {
          float: right;
          font-size: 13px;
          color: #5b5b5b;
          opacity: 0.6;
        }
      }
    }

    .comment-text {
      padding: 10px 0 0 0;
      display: inline-block;
    }
  }
}

/* Calendar */

.fc-toolbar h2 {
  font-size: 18px !important;
  font-weight: normal;
  color: #5b5b5b;
}

.fc-prev-button.fc-button.fc-button-primary, .fc-next-button.fc-button.fc-button-primary, .fc-prev-button.fc-button.fc-button-primary.active, .fc-next-button.fc-button.fc-button-primary.active, .fc-prev-button.fc-button.fc-button-primary:focus, .fc-next-button.fc-button.fc-button-primary:focus {
  background-color: #f0f2ff;
  border-color: #f0f2ff;
  color: #7888FC;
  border-radius: 10px;
  padding: 8px 5px;
}

.fc-prev-button.fc-button.fc-button-primary:hover, .fc-next-button.fc-button.fc-button-primary:hover {
  background-color: #f0f2ff;
  border-color: #f0f2ff;
  color: #7888FC;
  box-shadow: 0 7px 23px -8px #f0f2ff;
}

.fc-today-button.fc-button.fc-button-primary {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #7e8299;
  border-radius: 10px;
  padding: 8px 20px;

  &:focus, &.active {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #7e8299;
    border-radius: 10px;
    padding: 8px 20px;
  }
}

.fc .fc-button-primary:disabled {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #7e8299;
}

#calendar a {
  color: #5b5b5b;
}

.fc-col-header-cell-cushion {
  font-weight: normal;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #F3F6FA;
}

.fc-scrollgrid-section.fc-scrollgrid-section-header {
  border: none;
}

.fc-h-event {
  background-color: #f0f2ff;
  border-color: #f0f2ff;
  padding: 3px;
  border-radius: 15px;
}

.fc-event-title.fc-sticky {
  padding: 2px 10px;
  color: #7888FC;
  font-weight: 500;
}

.fc-daygrid-event {
  border-radius: 15px;

  &:hover {
    background-color: #F3F6FA;
  }
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: normal;
}

.fc-daygrid-event-dot {
  border-color: #7888FC;
}
