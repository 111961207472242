/*------------------------------------------------------------
[4. PAGE CONTENT]
------------------------------------------------------------*/

.page-content {
  margin-top: 71px;
  margin-left: 260px;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.main-wrapper {
  padding: 28px 0 28px 28px;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

body.sidebar-hidden {
  .page-content {
    margin-left: 0;
  }

  .main-wrapper {
    padding-left: 0;
  }
}
