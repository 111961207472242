/*------------------------------------------------------------
[3. LAYOUT]
------------------------------------------------------------*/

.page-container {
  padding: 20px;
  position: relative;
  display: block;
}

/* Page Header */

.page-header {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
  position: fixed;
  width: calc(100% - 40px);
  z-index: 999;
  border: 1px solid #E5E9F2;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 105px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, hsla(0, 0%, 97.3%, 0.94)), color-stop(73%, hsla(0, 0%, 97.3%, 0.49)), to(hsla(0, 0%, 100%, 0)));
    position: fixed;
    top: 0;
    left: 0;
  }

  .navbar {
    background: #fff;
    border-radius: 15px;
    padding: 15px 25px;
    display: flex !important;

    .logo {
      display: flex !important;
      flex: 2;
    }

    #navbarNav #leftNav > li > a {
      color: #5b5b5b;
      font-size: 14px;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      border-radius: 10px;
      padding: 9px 10px;
      line-height: 22px;

      &:hover {
        background: #F3F6FA;
        color: #7889FF;
      }

      i {
        line-height: 22px;
        vertical-align: middle;
        font-size: 22px;
      }

      &#sidebar-toggle {
        padding: 9px 0;
        margin-right: 10px;
        transform: rotate(0deg);
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
      }
    }
  }
}

body.sidebar-hidden .page-header .navbar #navbarNav #leftNav > li > a#sidebar-toggle {
  transform: rotate(-180deg);
}

.page-header {
  .navbar {
    #navbarNav #leftNav > li > a#sidebar-toggle:hover {
      background: transparent;
    }

    #headerNav, #navbarNav {
      display: flex !important;
      flex: 10;
    }

    #headerNav .navbar-nav, #navbarNav .navbar-nav {
      flex-direction: row !important;
    }
  }

  #sidebar-toggle svg {
    width: 22px;
    height: 22px;
  }

  .navbar {
    #headerNav > ul > li > a {
      padding: 9px 0;
      margin: 0 10px;
      color: #5b5b5b;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;

      svg {
        line-height: 22px;
        vertical-align: middle;
        width: 21px;
        height: 21px;
      }

      &:hover {
        color: #7889FF;
      }

      &.notifications-dropdown {
        padding: 9px 15px;
        background: #fad7dd;
        color: #EE6E83;
        line-height: 22px;
        border-radius: 40px;
        min-width: 40px;
        text-align: center;
        font-weight: bold;
      }

      &.profile-dropdown {
        padding: 0;
        margin-right: 0;
      }

      img {
        width: 40px;
      }
    }

    .navbar-nav {
      flex: 10;
      padding: 0;
    }

    .navbar-brand {
      margin-right: 0;
      // background: url('assets/images/logo@2x.png') center center no-repeat;
      background-size: cover;
      width: 40px;
      height: 40px;
      margin: 0 auto;
    }
  }

  #headerNav {
    flex: 10;
    align-items: flex-end;
    flex-direction: column;
  }

  .header-notif {
    padding: 13px 26px;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    &:hover {
      background-color: #F3F6F9;
    }

    .notif-image {
      float: left;
      margin-right: 10px;
      margin-top: 3px;
      border-radius: 50%;

      span {
        width: 40px;
        height: 40px;
        display: block;
        line-height: 40px;
        text-align: center;
        border-radius: 100%;
        display: inline-block;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .notif-text {
      width: 223px;
      display: block;
      padding: 0 5px;
      display: inline-block;
      color: #5b5b5b;
      font-weight: 400;

      .bold-notif-text {
        font-weight: 500;
      }

      p {
        margin: 0;
      }

      small {
        opacity: 0.7;
      }
    }
  }
}

.profile-drop-menu a span {
  margin-left: 25px;
}

.search-drop-menu {
  width: 340px;
}

.notif-drop-menu {
  width: 340px;
  max-height: 420px;
  overflow-y: auto;

  .dropdown-header {
    margin-bottom: 5px;
  }
}

.profile-drop-menu a svg {
  margin-right: 10px;
  width: 19px;
  height: 19px;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
  visibility: hidden;
  opacity: 0;
  margin-top: 30px;
  display: block;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  &.show {
    margin-top: 10px;
    opacity: 1;
    visibility: visible;
  }
}

/* SlimScroll */

.slimScrollBar {
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
}

/* Page Sidebar */

.page-sidebar {
  position: fixed;
  height: calc(100% - 148px);
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
  top: 118px;
  border-radius: 15px;
  width: 260px;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  transform: scale(1, 1);
  transform-origin: left;
  overflow: visible;

  .ps__rail-y {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    background-color: transparent !important;
  }

  .accordion-menu {
    height: 100% !important;
    padding: 20px 15px;

    > li {
      > a {
        display: block;
        color: #5b5b5b;
        -webkit-transition: all .1s ease-in-out;
        -moz-transition: all .1s ease-in-out;
        -o-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
        line-height: 45px;
        padding: 0 15px;
        text-decoration: none;
      }

      &.active-page {
        > a {
          border-radius: 10px;
          background-color: #F3F6F9;
          color: #7888FC;
          font-weight: 500;

          > svg {
            color: #7888FC !important;
          }
        }

        ul li a.active {
          font-weight: 500;
          color: #3d3d3d;
        }
      }

      > a {
        &:hover svg {
          margin-left: 5px;
        }

        > svg {
          width: 21px;
          height: 21px;
          line-height: 40px;
          text-align: center;
          vertical-align: text-top;
          color: #5b5b5b;
          margin-right: 15px;
          -webkit-transition: all .2s ease-in-out;
          -moz-transition: all .2s ease-in-out;
          -o-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
        }
      }
    }

    li {
      &.sidebar-title {
        font-weight: 500;
        padding: 10px 15px;
        font-size: .875rem;
        color: #6c757d;
        opacity: 0.8;
      }

      a .dropdown-icon {
        float: right;
        vertical-align: middle;
        line-height: 44px;
        font-size: 10px;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
      }

      &.open > a > .dropdown-icon {
        visibility: visible;
        transform: rotate(90deg);
      }

      ul {
        padding: 5px 0;
        list-style: none;

        li a {
          color: #5b5b5b;
          display: block;
          padding: 10px 15px;
          font-size: 14px;
          position: relative;
          -webkit-transition: all .15s ease-in-out;
          -moz-transition: all .15s ease-in-out;
          -o-transition: all .15s ease-in-out;
          transition: all .15s ease-in-out;
          text-decoration: none;

          &:hover {
            margin-left: 5px;
          }

          i {
            font-size: 10px;
            padding-right: 21px;
            padding-left: 6px;
          }
        }
      }
    }
  }
}

body.sidebar-hidden .page-sidebar {
  transform: scale(0, 1);
}
